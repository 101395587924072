<template>
  <table>
    <thead>
    <tr>
      <th class="center-align" style="width: 100px">ID</th>
      <th style="width: 30%">Название</th>
      <th style="width: 200px">Объединение</th>
      <th style="width: 150px">ИНН</th>
      <th>Адрес</th>
    </tr>
    </thead>

    <tbody>
    <tr v-for="(record, idx) of records" :key="record.id">
      <td class="center-align">
        <router-link :to="{path: '/customers/' + record.id}"
                     v-tooltip="'Открыть'"
                     class="btn-small btn blue darken-4">
          {{record.id}}
        </router-link>
      </td>
      <td>{{record.name}}</td>
      <td>{{record.association}}</td>
      <td>{{record.inn}}</td>
      <td>{{record.address_actual}}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    records: {
      required: true,
      type: Array
    }
  }
}
</script>
