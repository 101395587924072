<template>
  <div>
    <div class="page-title">
      <h3>Контрагенты</h3>
      <span>
        <a class="btn-floating btn-large waves-effect waves-light red"
           v-tooltip="'Добавить'"
           @click="$router.push('/customers/add')">
          <i class="material-icons">add</i>
        </a>
      </span>
    </div>

    <Loader v-if="loading"/>

    <p class="center" v-else-if="!records.length">
      {{'Нет записей'}}
    </p>

    <section v-else>
      <CustomersTable :records="records"/>
    </section>
  </div>
</template>

<script>
  import paginationMixin from '@/mixins/pagination.mixin'
  import CustomersTable from '@/components/CustomersTable'

  export default {
    name: 'tasks',
    metaInfo() {
      return {
        title: this.$title('Контрагенты')
      }
    },
    mixins: [paginationMixin],
    data: () => ({
      loading: true,
      records: []
    }),
    async mounted() {
      this.records = await this.$store.dispatch('fetchCustomers')
      this.loading = false
    },
    methods: {
    },
    components: {
      CustomersTable
    }
  }
</script>
